<template>
  <div>
    <v-row>
      <v-col :cols="noDetailsSelected ? '6' : '9'">
        <v-card>
          <v-card-title>
            <v-sheet max-width="75%">
              <v-slide-group>
                <template v-slot:prev>
                  <v-icon ref="pathPrev">
                    mdi-chevron-left
                  </v-icon>
                </template>
                <v-slide-item v-slot:default="{ active, toggle }">
                  <span class="pa-1">
                    <v-btn
                      small
                      :input-value="blobFolders.length === 0"
                      active-class="secondary"
                      @click="rootFolder"
                    >
                      Root
                      <v-icon
                        v-if="blobFolders.length === 0"
                        right
                      >mdi-folder-open</v-icon>
                    </v-btn>
                    <v-icon
                      v-if="blobFolders.length > 0"
                      class="pl-2"
                    >mdi-chevron-right</v-icon>
                  </span>
                </v-slide-item>
                <v-slide-item
                  v-for="(item, i) in blobFolders"
                  :key="i"
                  v-slot:default="{ active, toggle }"
                >
                  <span class="pa-1">
                    <v-btn
                      ref="pathLast"
                      small
                      :input-value="i === blobFolders.length - 1"
                      active-class="secondary"
                      @click="updateFolderPath(blobFolders.slice(0, i+1).join('/'))"
                    >
                      {{ item }}
                      <v-icon
                        v-if="i === blobFolders.length - 1"
                        right
                      >mdi-folder-open</v-icon>
                    </v-btn>
                    <v-icon
                      v-if="i !== blobFolders.length - 1"
                      class="pl-2"
                    >mdi-chevron-right</v-icon>
                  </span>
                </v-slide-item>
                <v-slide-item v-slot:default="{ active, toggle }">
                  <v-sheet
                    class="ml-4 ma-1"
                    elevation="4"
                  >
                    <v-menu
                      v-if="!noRenameFolder && blobFolders.length > 0"
                      v-model="renameFolderMenu"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              right
                              color="success"
                              class="px-1"
                              :disabled="isLoading"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-folder-edit
                            </v-icon>
                          </template>
                          <span>Rename folder</span>
                        </v-tooltip>
                      </template>

                      <v-card :width="menuWidth">
                        <v-card-text class="px-5 py-2">
                          <v-text-field
                            v-model="renameFolder"
                            :error-messages="renameFolderErrors"
                            required
                            label="Folder Name"
                            :loading="renamingFolder"
                            @input="$v.renameFolder.$touch()"
                            @blur="$v.renameFolder.$touch()"
                            @keyup.enter="renameFolderSave(renameFolder)"
                          />
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="renamingFolder || isLoading"
                            @click="closeRenameFolder"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="success"
                            :loading="renamingFolder || isLoading"
                            @click="renameFolderSave(renameFolder)"
                          >
                            Rename
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <v-menu
                      v-if="!noDeleteFolder && blobFolders.length > 0"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              color="error"
                              class="px-1"
                              :disabled="isLoading"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-folder-remove
                            </v-icon>
                          </template>
                          <span>Delete folder</span>
                        </v-tooltip>
                      </template>

                      <v-card>
                        <v-card-text class="pa-5">
                          <span>Are you sure you want to delete this folder?</span>
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="isLoading"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            :loading="isLoading"
                            @click="deleteFolder()"
                          >
                            Yes
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <v-menu
                      v-if="!noCreateFolder"
                      v-model="createFolderMenu"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              :right="blobFolders.length === 0"
                              color="success"
                              class="px-1"
                              :disabled="isLoading"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-folder-plus
                            </v-icon>
                          </template>
                          <span>Create folder</span>
                        </v-tooltip>
                      </template>

                      <v-card :width="menuWidth">
                        <v-card-text class="px-5 py-2">
                          <v-text-field
                            v-model="createFolder"
                            :error-messages="createFolderErrors"
                            required
                            label="Folder Name"
                            @input="$v.createFolder.$touch()"
                            @blur="$v.createFolder.$touch()"
                            @keyup.enter="createFolderSave(createFolder)"
                          />
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="isLoading"
                            @click="closeCreateFolder"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="success"
                            :loading="isLoading"
                            @click="createFolderSave(createFolder)"
                          >
                            Create
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <v-tooltip
                      v-if="!noDownloadFolder"
                      top
                    >
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          color="success"
                          class="px-1"
                          :disabled="isLoading"
                          v-on="onTooltip"
                          @click="downloadFolder(currentPath)"
                        >
                          mdi-folder-download
                        </v-icon>
                      </template>
                      <span>Download folder</span>
                    </v-tooltip>
                    <v-menu
                      v-if="!noUploadFiles"
                      v-model="uploadFilesMenu"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              :left="root !== htmlRoot || noNewCreative"
                              color="success"
                              class="px-1"
                              :disabled="isLoading"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-upload
                            </v-icon>
                          </template>
                          <span>Upload file(s)</span>
                        </v-tooltip>
                      </template>
                      <v-card :width="menuWidth">
                        <v-card-text class="px-5 py-2">
                          <v-file-input
                            v-model="uploadFiles"
                            :rules="uploadFilesRules"
                            :accept="acceptedFileExtensions"
                            :show-size="1000"
                            :error-messages="uploadFilesErrors"
                            multiple
                            counter
                            color="secondary"
                            label="Upload File(s)"
                            @change="uploadFilesChange"
                            @click:clear="clearUploadFilesFormDataFiles()"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="secondary"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>
                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >+{{ uploadFiles.length - 2 }} File(s)</span>
                            </template>
                          </v-file-input>
                          <v-text-field
                            v-model="oneDriveFileNames"
                            prepend-icon="mdi-microsoft-onedrive"
                            :append-icon="hasOneDriveAttachment() ? 'mdi-close' : ''"
                            label="Upload File(s) from OneDrive"
                            readonly
                            dense
                            @click:prepend="openOneDrive()"
                            @click:append="clearUploadFilesFormDataOneDrive()"
                            @click="openOneDrive()"
                          />
                          <div class="font-italic pl-7 inline-block">
                            Valid file extensions: {{ acceptedFileExtensions }}
                          </div>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="uploadingFiles || isLoading"
                            @click="closeUploadFiles"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="success"
                            :loading="uploadingFiles || isLoading"
                            @click="uploadFilesSave()"
                          >
                            Upload
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <v-menu
                      v-if="root === htmlRoot && !noNewCreative"
                      v-model="newCreativeMenu"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :close-on-click="false"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              left
                              color="success"
                              class="px-1"
                              :disabled="isLoading"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-text-box-plus
                            </v-icon>
                          </template>
                          <span>New Creative</span>
                        </v-tooltip>
                      </template>
                      <v-card :width="menuWidth">
                        <v-card-text class="px-5 py-2">
                          <v-text-field
                            v-model="newCreative"
                            :error-messages="newCreativeErrors"
                            required
                            label="File Name"
                            :loading="creatingCreative"
                            @input="$v.newCreative.$touch()"
                            @blur="$v.newCreative.$touch()"
                            @keyup.enter="addCreative(newCreative)"
                          >
                            <template v-slot:append>
                              {{ newCreativeExt }}
                            </template>
                          </v-text-field>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="creatingCreative || isLoading"
                            @click="closeNewCreative()"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="success"
                            :loading="creatingCreative || isLoading"
                            @click="addCreative(newCreative)"
                          >
                            Create
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-slide-item>
                <template v-slot:next>
                  <v-icon ref="pathNext">
                    mdi-chevron-right
                  </v-icon>
                </template>
              </v-slide-group>
            </v-sheet>
            <v-spacer />
            <v-sheet
              v-if="!noDeleteChecked"
              class="pr-3"
              elevation="4"
            >
              <v-menu
                right
                x-offset
                origin="right"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        :disabled="!checkedItems || checkedItems.length === 0"
                        fab
                        small
                        icon
                        color="error"
                        height="30"
                        class="px-1"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete {{ checkedItems.length }} checked item(s)</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-text class="pa-5">
                    <span>Are you sure you want to delete the checked items?</span>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="tertiary"
                      :disabled="isLoading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="error"
                      :loading="isLoading"
                      @click="deleteChecked()"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
            <v-badge
              v-if="!noDeleteChecked"
              :content="checkedItems.length"
              overlap
              :color="checkedItems && checkedItems.length > 0 ? 'secondary' : 'grey'"
            />
            <v-sheet
              v-if="!noDownloadChecked"
              class="pr-3 ml-4"
              elevation="4"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on: onTooltip }">
                  <v-btn
                    :disabled="!filesChecked || filesChecked.length === 0"
                    fab
                    small
                    icon
                    color="success"
                    height="30"
                    class="px-1"
                    v-on="onTooltip"
                    @click="downloadChecked"
                  >
                    <v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </template>
                <span>Download {{ filesChecked.length }} checked file(s)</span>
              </v-tooltip>
            </v-sheet>
            <v-badge
              v-if="!noDownloadChecked"
              :content="filesChecked.length"
              overlap
              :color="filesChecked && filesChecked.length > 0 ? 'secondary' : 'grey'"
            />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-skeleton-loader
              v-for="index in 12"
              v-show="isLoading"
              :key="index"
              text
              type="text"
              class="my-4 ml-7"
              width="500"
            />
            <v-treeview
              v-show="loadingCount === 0"
              :items="blobs"
              dense
              hoverable
              :selectable="checkable"
              activatable
              :active="selectedItems"
              selected-color="secondary"
              open-on-click
              class="pt-1"
              style="height: 46vh; overflow-y: auto;"
              @update:active="itemSelected"
              @input="checkItems"
            >
              <template v-slot:prepend="{ item }">
                <v-icon>{{ item.displayIcon }}</v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="!noDetailsSelected"
        cols="3"
      >
        <v-card
          v-if="itemProps"
          class="my-primary"
          height="57vh"
          style="overflow-y: auto;"
        >
          <v-card-title class="pb-2 d-flex flex-column">
            <div class="primary--text ma-1">
              <v-icon
                left
                class="primary--text"
              >
                {{ itemProps.displayIcon }}
              </v-icon>
              {{ itemProps.name }}
            </div>
            <div>
              <v-sheet
                class="ma-1 py-1 d-flex align-center"
                elevation="4"
              >
                <v-tooltip
                  v-if="root === htmlRoot && !noNewCreative"
                  top
                >
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-icon
                      right
                      color="success"
                      class="px-1"
                      :disabled="isLoading"
                      v-on="onTooltip"
                      @click="editCreative"
                    >
                      mdi-file-document-edit
                    </v-icon>
                  </template>
                  <span>Edit Creative</span>
                </v-tooltip>
                <v-menu
                  v-model="renameSelectedMenu"
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :width="menuWidth"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          :right="root !== htmlRoot || noNewCreative"
                          color="success"
                          class="px-1"
                          :disabled="isLoading"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          mdi-file-document-outline
                        </v-icon>
                      </template>
                      <span>Rename file</span>
                    </v-tooltip>
                  </template>
                  <v-card :width="menuWidth">
                    <v-card-text class="px-5 py-2">
                      <v-text-field
                        v-model="renameSelected"
                        :error-messages="renameSelectedErrors"
                        required
                        label="File Name"
                        :loading="renamingSelected"
                        @input="$v.renameSelected.$touch()"
                        @blur="$v.renameSelected.$touch()"
                        @keyup.enter="renameSelectedSave(renameSelected)"
                      >
                        <template v-slot:append>
                          {{ renameSelectedExt }}
                        </template>
                      </v-text-field>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="renamingSelected || isLoading"
                        @click="closeRenameSelected"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        :loading="renamingSelected || isLoading"
                        @click="renameSelectedSave(renameSelected)"
                      >
                        Rename
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-menu
                  v-if="!noDeleteSelected"
                  right
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                  :width="menuWidth"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          color="error"
                          class="px-1"
                          :disabled="isLoading"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          mdi-file-cancel
                        </v-icon>
                      </template>
                      <span>Delete file</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-text class="pa-5">
                      <span>Are you sure you want to delete this file?</span>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="error"
                        :loading="isLoading"
                        @click="deleteSelected()"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-icon
                      left
                      color="success"
                      class="px-1"
                      :disabled="isLoading"
                      v-on="onTooltip"
                      @click="downloadSelected"
                    >
                      mdi-file-download
                    </v-icon>
                  </template>
                  <span>Download file</span>
                </v-tooltip>
              </v-sheet>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list class="my-primary">
              <v-list-item
                v-if="itemProps.url"
                single-line
              >
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    <v-dialog
                      v-model="itemProps.showImageSelected"
                      persistent
                    >
                      <template v-slot:activator="{ on: onDialog }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-img
                              :src="`${itemProps.url}`"
                              class="grey lighten-2 pointer"
                              v-on="{ ...onDialog, ...onTooltip }"
                            >
                              <template v-slot:placeholder>
                                <v-skeleton-loader
                                  type="image"
                                />
                              </template>
                            </v-img>
                          </template>
                          <span>Preview image</span>
                        </v-tooltip>
                      </template>
                      <v-card>
                        <v-card-text class="pt-4">
                          <v-img
                            :src="itemProps.url"
                            class="grey lighten-2"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="isLoading"
                            @click="closeImage()"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="itemProps.metadata && itemProps.metadata.Width && itemProps.metadata.Height"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>Dimensions (px)</v-list-item-title>
                  <v-list-item-subtitle
                    class="primary--text"
                  >
                    {{ itemProps.metadata.Width }}x{{ itemProps.metadata.Height }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(value, name) in itemPropsProperties"
                :key="name"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>{{ name | camelToSpace }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="name === 'createdOn'"
                    class="primary--text"
                  >
                    {{ value }} {{ itemProps.properties['createdBy'] ? `by ${itemProps.properties['createdBy']}` : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="name === 'lastModified'"
                    class="primary--text"
                  >
                    {{ value }} {{ itemProps.properties['modifiedBy'] ? `by ${itemProps.properties['modifiedBy']}` : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else
                    class="primary--text"
                  >
                    {{ value }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-else
        cols="6"
        style="max-height: 550px; overflow: hidden;"
      >
        <v-skeleton-loader
          v-if="!previewHtml && loadingHtml"
          type="article@4"
        />
        <div
          v-else-if="previewHtml"
          style="max-height: 550px; overflow-y: scroll;"
        >
          <froala-view
            v-model="previewHtml"
          />
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="clEditorDialog"
      persistent
    >
      <ContentLibraryEditor
        @closeDialog="closeCLEditorDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import '@/plugins/vue-froala-wysiwyg'
import moment from 'moment'
import constants from '@/shared/constants'
import { fileIcons, fileExtensions, CreativeModel } from '@/shared/models/content-library'
import { downloadFileMixin } from '@/shared/mixins/general'
import { oneDriveMixin } from '@/shared/mixins/scripts'
import { validationMixin } from 'vuelidate'
import { noPeriods, noSpecialCharacters } from '@/shared/validators'
import { required, helpers } from 'vuelidate/lib/validators'
import { azBlobService } from '@/shared/services'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mutationTypes from '@/store/mutation-types'
import ContentLibraryEditor from '@/views/pages/components/content-library/ContentLibraryEditor.vue'

const noElementMatches = (
  arrFieldName,
  concatFieldName,
  matches = (e, v) => e === v
) =>
  function (value, parentVm) {
    return !helpers
      .ref(arrFieldName, this, parentVm)
      .some(el =>
        matches(
          el,
          `${value}${
            concatFieldName ? helpers.ref(concatFieldName, this, parentVm) : ''
          }`
        )
      )
  }

export default {
  name: 'ContentLibraryMain',
  components: { ContentLibraryEditor },
  mixins: [validationMixin, downloadFileMixin, oneDriveMixin],
  props: {
    root: {
      type: String,
      default: process.env.VUE_APP_CL_IMAGES
    },
    fileUploadAcceptedExtensions: {
      type: Array,
      default: () => (fileExtensions.imageExtensionsList)
    },
    noRenameFolder: {
      type: Boolean,
      default: false
    },
    noDeleteFolder: {
      type: Boolean,
      default: false
    },
    noCreateFolder: {
      type: Boolean,
      default: false
    },
    noDownloadFolder: {
      type: Boolean,
      default: false
    },
    noUploadFiles: {
      type: Boolean,
      default: false
    },
    noNewCreative: {
      type: Boolean,
      default: false
    },
    noDeleteChecked: {
      type: Boolean,
      default: false
    },
    noDownloadChecked: {
      type: Boolean,
      default: false
    },
    noDeleteSelected: {
      type: Boolean,
      default: false
    },
    noDetailsSelected: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: true
    }
  },

  validations: {
    renameFolder: {
      required,
      noPeriods
    },
    renameSelected: {
      required,
      noPeriods,
      noElementMatches: noElementMatches(
        'blobs',
        'renameSelectedExt',
        ({ name }, value) => name.toUpperCase() === value.toUpperCase()
      )
    },
    newCreative: {
      required,
      noPeriods,
      noElementMatches: noElementMatches(
        'blobs',
        'newCreativeExt',
        ({ name }, value) => name.toUpperCase() === value.toUpperCase()
      )
    },
    createFolder: {
      required,
      noPeriods,
      noSpecialCharacters,
      noElementMatches: noElementMatches(
        'blobs',
        null,
        ({ name }, value) => name.toUpperCase() === value.toUpperCase()
      )
    }
  },

  data: () => ({
    blobs: [],
    blobFolders: [],
    selectedItems: [],
    itemProps: null,
    loadingCount: 0,
    renameFolder: '',
    renameFolderMenu: false,
    renameFolderAvail: true,
    renamingFolder: false,
    createFolder: '',
    createFolderMenu: false,
    renameSelected: '',
    renameSelectedExt: '',
    renameSelectedMenu: false,
    renamingSelected: false,
    newCreativeMenu: false,
    newCreative: '',
    newCreativeExt: '.html',
    creatingCreative: false,
    checkedItems: [],
    uploadFiles: null,
    oneDriveFileNames: null,
    uploadFilesFormData: new FormData(),
    clEditorDialog: false,
    // clEditorDialogShowTemplates: false,
    loadingHtml: false,
    previewHtml: '',
    uploadFilesRules: [
      value =>
        !value ||
        !value.some(x => x.size > 5000000) ||
        'File size should be less than 5 MB',
      value =>
        !value || value.length <= 20 || 'Can only upload 20 files at a time'
    ],
    uploadFilesErrors: [],
    uploadFilesMenu: false,
    uploadingFiles: false,
    menuWidth: 500,
    htmlRoot: process.env.VUE_APP_CL_HTML,
    unsubscribe: null
  }),

  computed: {
    ...mapGetters(['simulatedCustomerCrmId']),
    renameFolderErrors () {
      const errors = []
      if (!this.$v.renameFolder.$dirty) return errors
      !this.$v.renameFolder.required && errors.push('Folder Name is required')
      !this.$v.renameFolder.noPeriods && errors.push('Folder Name cannot contain "."')
      !this.renameFolderAvail &&
        errors.push('Folder name is already used in the parent folder')
      return errors
    },
    renameSelectedErrors () {
      const errors = []
      if (!this.$v.renameSelected.$dirty) return errors
      !this.$v.renameSelected.required && errors.push('File Name is required')
      !this.$v.renameSelected.noPeriods && errors.push('File Name cannot contain "."')
      !this.$v.renameSelected.noElementMatches &&
        errors.push('File Name is already used in this folder')
      return errors
    },
    newCreativeErrors () {
      const errors = []
      if (!this.$v.newCreative.$dirty) return errors
      !this.$v.newCreative.required && errors.push('File Name is required')
      !this.$v.newCreative.noPeriods && errors.push('File Name cannot contain "."')
      !this.$v.newCreative.noElementMatches &&
        errors.push('File Name is already used in this folder')
      return errors
    },
    createFolderErrors () {
      const errors = []
      if (!this.$v.createFolder.$dirty) return errors
      !this.$v.createFolder.required && errors.push('Folder Name is required')
      !this.$v.createFolder.noPeriods && errors.push('Folder Name cannot contain "."')
      !this.$v.createFolder.noSpecialCharacters && errors.push('Folder Name cannot contain special chars except "_" and "-"')
      !this.$v.createFolder.noElementMatches &&
        errors.push('Folder Name is already used in this folder')
      return errors
    },
    acceptedFileExtensions () {
      return fileExtensions.getExtensionsString(this.fileUploadAcceptedExtensions)
    },
    currentPath () {
      return (
        this.root +
        (this.blobFolders.length > 0
          ? this.blobFolders.join('/').concat('/')
          : '')
      )
    },
    filesChecked () {
      return this.checkedItems.filter(x => !x.endsWith('/'))
    },
    isLoading () {
      return this.loadingCount > 0
    },
    itemPropsProperties () {
      var _itemProps = {}
      for (const property in this.itemProps.properties) {
        if (property !== 'createdBy' && property !== 'modifiedBy') {
          _itemProps[property] = this.itemProps.properties[property]
        }
      }
      return _itemProps
    }
  },

  watch: {
    blobFolders (newValue, oldValue) {
      this.checkedItems = []
      this.closeRenameSelected()
      this.closeCreateFolder()
      this.closeUploadFiles()
      this.closeRenameFolder()
      this.loadBlobs()
      setTimeout(() => {
        if (this.$refs.pathNext) this.$refs.pathNext.$el.click()
        else if (this.$refs.pathPrev) this.$refs.pathPrev.$el.click()
        // else this.$refs.pathLast.$el.click()
      }, 100)
      this.unselect()
    },
    itemProps (newValue, oldValue) {
      this.closeRenameSelected()
    },
    renameFolder (newValue, oldValue) {
      this.renameFolderAvail = true
    },
    renameFolderMenu (newValue, oldValue) {
      if (newValue) {
        this.renameFolder = this.blobFolders[this.blobFolders.length - 1]
        // this.$nextTick(this.$refs.renameFolder.$refs.textfield.focus());
      }
    },
    renameSelectedMenu (newValue, oldValue) {
      if (newValue) {
        this.renameSelected = this.itemProps.name.substr(
          0,
          this.itemProps.name.lastIndexOf('.')
        )
        this.renameSelectedExt = this.itemProps.name.substr(
          this.itemProps.name.lastIndexOf('.')
        )
      }
    },
    currentPath (newValue, oldValue) {
      this.uploadFilesFormData.set('root', this.currentPath)
    }
  },

  created () {
    this.loadBlobs()
    this.uploadFilesFormData.set('tenantCrmId', this.simulatedCustomerCrmId())
    this.uploadFilesFormData.set('root', this.currentPath)
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.blobFolders = []
        this.uploadFilesFormData.set('tenantCrmId', this.simulatedCustomerCrmId())
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    ...mapActions('creative', ['getSelectedCreative', 'createCreative']),
    ...mapMutations('creative', ['clearCreative']),
    async loadBlobs () {
      this.loadingCount++
      await azBlobService
        .browse({
          tenantCrmId: this.simulatedCustomerCrmId(),
          root: this.currentPath
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.blobs = []
            this.itemProps = null
          } else {
            resp.content.forEach((blob, i) => {
              resp.content[i]['displayIcon'] = this.resolveIcon(blob)
              for (var prop in blob.properties) {
                let checkIfDate = moment(blob.properties[prop])
                if (checkIfDate.isValid()) {
                  resp.content[i]['properties'][prop] = checkIfDate
                    .local()
                    .format(constants.displayDateTimeFormat)
                }
              }
            })
            this.blobs = resp.content
            this.itemProps = null
          }
        })
        .finally(() => {
          this.loadingCount--
        })
    },
    resolveIcon (item) {
      if (!item.isBlob) return 'mdi-folder'
      return fileIcons.resolveIconByFileName(item.name)
    },
    unselect () {
      this.selectedItems = []
      this.itemProps = null
      if (this.noDetailsSelected) {
        this.loadingHtml = false
        Vue.set(this, 'previewHtml', '')
      }
      this.$emit('unselected')
    },
    itemSelected (itemSelected) {
      if (itemSelected.length === 0) {
        this.unselect()
        return
      }
      let itemSelect = itemSelected[0]
      let blobRef = this.blobs.find(x => x.id === itemSelect)
      if (!blobRef.isBlob) {
        this.unselect()
        if (itemSelect.startsWith(this.root)) {
          itemSelect = itemSelect.replace(this.root, '')
        }
        itemSelect = itemSelect.substring(0, itemSelect.length - 1)
        this.blobFolders = itemSelect.split('/')
      } else {
        this.itemProps = blobRef
        this.$emit('selected', this.itemProps)
        if (this.noDetailsSelected) {
          Vue.set(this, 'previewHtml', '')
          this.loadingHtml = true
          azBlobService.getCLHtml({
            tenantCrmId: this.simulatedCustomerCrmId(),
            path: `${this.itemProps.id}${this.itemProps.name}`,
            isPreview: true
          }, true)
            .then(resp => {
              Vue.set(this, 'previewHtml', resp)
            }).finally(() => {
              this.loadingHtml = false
            })
        }
      }
    },
    async renameSelectedSave (name) {
      this.$v.renameSelected.$touch()
      if (this.$v.renameSelected.$invalid) {
        return
      }
      this.renamingSelected = true
      name = name + this.renameSelectedExt
      await azBlobService.renameBlob({
        tenantCrmId: this.simulatedCustomerCrmId(),
        root: this.root,
        existingName: this.currentPath + this.itemProps.name,
        newName: this.currentPath + name
      })
      this.renamingSelected = false
      let blobRef = this.blobs.find(x => x.id === this.itemProps.id)
      if (this.root === this.htmlRoot) {
        this.itemProps.id = this.itemProps.id.replace(this.itemProps.name, name)
        blobRef.id = this.itemProps.id
        this.selectedItems = [this.itemProps.id]
      }
      this.itemProps.name = name
      blobRef.name = name
      this.closeRenameSelected()
    },
    closeRenameSelected () {
      this.renameSelectedMenu = false
    },
    async deleteSelected () {
      this.$store.commit('setLoadingMessage', 'Deleting')
      await azBlobService.deleteItems({
        tenantCrmId: this.simulatedCustomerCrmId(),
        root: this.root,
        blobs: [`${this.currentPath}${this.itemProps.name}`]
      })
      this.blobs.splice(
        this.blobs.findIndex(x => x.id === this.itemProps.id),
        1
      )
      this.itemProps = null
    },
    async downloadSelected () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading')
      await azBlobService
        .downloadBlobs({
          tenantCrmId: this.simulatedCustomerCrmId(),
          root: this.root,
          blobs: [this.itemProps.id]
        })
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    checkItems (items) {
      this.checkedItems = items
    },
    async deleteChecked () {
      this.$store.commit('setLoadingMessage', 'Deleting checked items')
      await azBlobService.deleteItems({
        tenantCrmId: this.simulatedCustomerCrmId(),
        root: this.root,
        blobs: this.checkedItems
      })
      this.blobs = this.blobs.filter(x => !this.checkedItems.includes(x.id))
      this.checkedItems = []
    },
    async downloadChecked () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading checked items')
      await azBlobService
        .downloadBlobs({
          tenantCrmId: this.simulatedCustomerCrmId(),
          root: this.root,
          blobs: this.filesChecked
        })
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    closeCLEditorDialog () {
      this.clEditorDialog = false
    },
    async editCreative () {
      this.$store.commit('setLoadingMessage', 'Loading Creative')
      var creative = new CreativeModel()
      var fullPath = this.itemProps.id + this.itemProps.name
      creative.Path = fullPath
      creative.TenantCrmId = this.simulatedCustomerCrmId()

      await this.getSelectedCreative({ model: creative })
      // this.clEditorDialogShowTemplates = false
      this.clEditorDialog = true
    },
    closeNewCreative () {
      this.newCreativeMenu = false
      this.newCreative = ''
    },
    async addCreative (name) {
      this.$v.newCreative.$touch()
      if (this.$v.newCreative.$invalid) {
        return
      }
      this.creatingCreative = true
      var creative = new CreativeModel()
      creative.Path = this.root
      if (this.currentPath != null && this.currentPath !== undefined) {
        creative.Path = this.currentPath
      }
      creative.TenantCrmId = this.simulatedCustomerCrmId()
      creative.FileName = name + this.newCreativeExt

      await this.createCreative({ model: creative })
      this.creatingCreative = false

      this.closeNewCreative()

      // this.clEditorDialogShowTemplates = true
      this.clEditorDialog = true
      this.loadBlobs()
    },
    updateFolderPath (itemPath) {
      this.blobFolders = itemPath.split('/')
    },
    rootFolder () {
      this.blobFolders = []
    },
    closeRenameFolder () {
      this.renameFolderMenu = false
      this.renameFolder = ''
    },
    renameLastFolder (name) {
      this.blobFolders.pop()
      this.blobFolders.push(name)
    },
    async renameFolderSave (name) {
      this.$v.renameFolder.$touch()
      if (this.$v.renameFolder.$invalid) {
        return
      }
      let newPath = `${this.root}${this.blobFolders
        .slice(0, -1)
        .join('/')}${this.blobFolders.length === 1 ? '' : '/'}${name}/`
      var _this = this
      this.renamingFolder = true
      await azBlobService
        .folderExists({
          tenantCrmId: this.simulatedCustomerCrmId(),
          root: newPath
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            if (resp) {
              _this.renameFolderAvail = false
              this.renamingFolder = false
            } else {
              _this.renameFolderAvail = true
              azBlobService.renameFolder({
                tenantCrmId: _this.simulatedCustomerCrmId(),
                existingName: _this.currentPath,
                newName: newPath,
                root: this.root
              })
                .then(resp1 => {
                  _this.closeRenameFolder()
                  _this.renameLastFolder(name)
                })
                .finally(() => {
                  this.renamingFolder = false
                })
            }
          }
        })
    },
    async deleteFolder () {
      await azBlobService.deleteItems({
        tenantCrmId: this.simulatedCustomerCrmId(),
        blobs: [this.currentPath],
        root: this.root
      })
      this.blobFolders.pop()
    },
    closeCreateFolder () {
      this.createFolderMenu = false
      this.createFolder = ''
    },
    async createFolderSave (name) {
      this.$v.createFolder.$touch()
      if (this.$v.createFolder.$invalid) {
        return
      }
      await azBlobService.createFolder({
        tenantCrmId: this.simulatedCustomerCrmId(),
        root: `${this.currentPath}${name}/`
      })
      this.closeCreateFolder()
      this.loadBlobs()
    },
    async downloadFolder (name) {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading folder')
      await azBlobService
        .downloadFolder({
          tenantCrmId: this.simulatedCustomerCrmId(),
          root: name
        })
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    closeUploadFiles () {
      this.uploadFilesMenu = false
      this.uploadFilesErrors = []
      this.uploadFiles = null
      this.clearUploadFilesFormDataFiles()
      this.clearUploadFilesFormDataOneDrive()
    },
    logFormData () {
      console.log(Array.from(this.uploadFilesFormData.entries()))
    },
    clearUploadFilesFormDataFiles () {
      this.uploadFilesFormData.delete('files[]')
      // this.logFormData()
    },
    clearUploadFilesFormDataOneDrive () {
      this.uploadFilesFormData.delete('downLink[]')
      this.uploadFilesFormData.delete('downFileName[]')
      this.oneDriveFileNames = null
      // this.logFormData()
    },
    setOneDriveFileNames () {
      var over2Count = -2
      for (var val of this.uploadFilesFormData.getAll('downFileName[]')) {
        if (over2Count === -2) {
          this.oneDriveFileNames = val
        } else if (over2Count === -1) {
          this.oneDriveFileNames += `, ${val}`
        }
        over2Count++
      }
      if (over2Count > 0) {
        this.oneDriveFileNames += `, +${over2Count} File(s)`
      }
    },
    uploadFilesChange (files) {
      if (!files) {
        this.uploadFilesErrors = ['Upload File(s) is required']
        this.clearUploadFilesFormDataFiles()
        return
      }
      this.uploadFilesErrors = []
      for (var i = 0; i < this.uploadFiles.length; i++) {
        this.uploadFilesFormData.append(`files[]`, this.uploadFiles[i])
      }
    },
    openOneDrive () {
      var _this = this
      var odOptions = {
        clientId: process.env.VUE_APP_ONEDRIVE_CLIENT_ID,
        action: 'download',
        multiSelect: true,
        advanced: {
          filter: this.acceptedFileExtensions
        },
        success: async function (response) {
          _this.clearUploadFilesFormDataOneDrive()
          for (var i = 0; i < response.value.length; i++) {
            let file = response.value[i]
            _this.uploadFilesFormData.append(`downLink[]`, file['@microsoft.graph.downloadUrl'])
            _this.uploadFilesFormData.append(`downFileName[]`, file.name)
          }
          _this.setOneDriveFileNames()
        },
        cancel: function () {
          _this.clearUploadFilesFormDataOneDrive()
        },
        error: function (e) {
          _this.clearUploadFilesFormDataOneDrive()
        }
      }
      OneDrive.open(odOptions)
    },
    async uploadFilesSave () {
      if (this.canUploadFiles()) {
        this.uploadingFiles = true
        await azBlobService.uploadBlobs(this.uploadFilesFormData)
          .then(resp => {
            if (resp.errorResponse) {
              this.uploadFilesErrors.push(resp.errorResponse)
            } else {
              this.closeUploadFiles()
              this.loadBlobs()
            }
          })
          .finally(() => {
            this.uploadingFiles = false
          })
      } else {
        this.uploadFilesErrors = ['Upload File(s) is required']
      }
    },
    hasOneDriveAttachment () {
      return this.uploadFilesFormData.has('downLink[]')
    },
    canUploadFiles () {
      return this.uploadFilesFormData &&
        (this.uploadFilesFormData.has('files[]') ||
        this.hasOneDriveAttachment())
    },
    closeImage () {
      this.itemProps.showImageSelected = false
    }
  }

}
</script>

<style lang="scss" scoped>
.my-primary {
  background-color: var(--v-primary-lighten5); //rgba(162, 94, 181, 0.12); //rgba(var(--v-primary-base), 0.12);
}

div.v-skeleton-loader__text.v-skeleton-loader__bone {
  margin-bottom: 12px !important;
}

</style>
