var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.noDetailsSelected ? "6" : "9" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-sheet",
                        { attrs: { "max-width": "75%" } },
                        [
                          _c(
                            "v-slide-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "prev",
                                  fn: function() {
                                    return [
                                      _c("v-icon", { ref: "pathPrev" }, [
                                        _vm._v(
                                          "\n                  mdi-chevron-left\n                "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next",
                                  fn: function() {
                                    return [
                                      _c("v-icon", { ref: "pathNext" }, [
                                        _vm._v(
                                          "\n                  mdi-chevron-right\n                "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("v-slide-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var active = ref.active
                                      var toggle = ref.toggle
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "pa-1" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  small: "",
                                                  "input-value":
                                                    _vm.blobFolders.length ===
                                                    0,
                                                  "active-class": "secondary"
                                                },
                                                on: { click: _vm.rootFolder }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Root\n                    "
                                                ),
                                                _vm.blobFolders.length === 0
                                                  ? _c(
                                                      "v-icon",
                                                      { attrs: { right: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-folder-open"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm.blobFolders.length > 0
                                              ? _c(
                                                  "v-icon",
                                                  { staticClass: "pl-2" },
                                                  [_vm._v("mdi-chevron-right")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._l(_vm.blobFolders, function(item, i) {
                                return _c("v-slide-item", {
                                  key: i,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var active = ref.active
                                          var toggle = ref.toggle
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "pa-1" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref: "pathLast",
                                                    refInFor: true,
                                                    attrs: {
                                                      small: "",
                                                      "input-value":
                                                        i ===
                                                        _vm.blobFolders.length -
                                                          1,
                                                      "active-class":
                                                        "secondary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.updateFolderPath(
                                                          _vm.blobFolders
                                                            .slice(0, i + 1)
                                                            .join("/")
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(item) +
                                                        "\n                    "
                                                    ),
                                                    i ===
                                                    _vm.blobFolders.length - 1
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { right: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-folder-open"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                i !== _vm.blobFolders.length - 1
                                                  ? _c(
                                                      "v-icon",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          "mdi-chevron-right"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              _c("v-slide-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var active = ref.active
                                      var toggle = ref.toggle
                                      return [
                                        _c(
                                          "v-sheet",
                                          {
                                            staticClass: "ml-4 ma-1",
                                            attrs: { elevation: "4" }
                                          },
                                          [
                                            !_vm.noRenameFolder &&
                                            _vm.blobFolders.length > 0
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition",
                                                      "close-on-content-click": false,
                                                      "close-on-click": false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onTooltip =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-1",
                                                                                  attrs: {
                                                                                    right:
                                                                                      "",
                                                                                    color:
                                                                                      "success",
                                                                                    disabled:
                                                                                      _vm.isLoading
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu,
                                                                                  onTooltip
                                                                                )
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            mdi-folder-edit\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Rename folder"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.renameFolderMenu,
                                                      callback: function($$v) {
                                                        _vm.renameFolderMenu = $$v
                                                      },
                                                      expression:
                                                        "renameFolderMenu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          width: _vm.menuWidth
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-5 py-2"
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "error-messages":
                                                                  _vm.renameFolderErrors,
                                                                required: "",
                                                                label:
                                                                  "Folder Name",
                                                                loading:
                                                                  _vm.renamingFolder
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.renameFolder.$touch()
                                                                },
                                                                blur: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.renameFolder.$touch()
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return _vm.renameFolderSave(
                                                                    _vm.renameFolder
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.renameFolder,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.renameFolder = $$v
                                                                },
                                                                expression:
                                                                  "renameFolder"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  disabled:
                                                                    _vm.renamingFolder ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.closeRenameFolder
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Cancel\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  loading:
                                                                    _vm.renamingFolder ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.renameFolderSave(
                                                                      _vm.renameFolder
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Rename\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.noDeleteFolder &&
                                            _vm.blobFolders.length > 0
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onTooltip =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-1",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "error",
                                                                                    disabled:
                                                                                      _vm.isLoading
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu,
                                                                                  onTooltip
                                                                                )
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            mdi-folder-remove\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Delete folder"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass: "pa-5"
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Are you sure you want to delete this folder?"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  disabled:
                                                                    _vm.isLoading
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Cancel\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  loading:
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteFolder()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Yes\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.noCreateFolder
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition",
                                                      "close-on-content-click": false,
                                                      "close-on-click": false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onTooltip =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-1",
                                                                                  attrs: {
                                                                                    right:
                                                                                      _vm
                                                                                        .blobFolders
                                                                                        .length ===
                                                                                      0,
                                                                                    color:
                                                                                      "success",
                                                                                    disabled:
                                                                                      _vm.isLoading
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu,
                                                                                  onTooltip
                                                                                )
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            mdi-folder-plus\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Create folder"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.createFolderMenu,
                                                      callback: function($$v) {
                                                        _vm.createFolderMenu = $$v
                                                      },
                                                      expression:
                                                        "createFolderMenu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          width: _vm.menuWidth
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-5 py-2"
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "error-messages":
                                                                  _vm.createFolderErrors,
                                                                required: "",
                                                                label:
                                                                  "Folder Name"
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.createFolder.$touch()
                                                                },
                                                                blur: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.createFolder.$touch()
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return _vm.createFolderSave(
                                                                    _vm.createFolder
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.createFolder,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.createFolder = $$v
                                                                },
                                                                expression:
                                                                  "createFolder"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  disabled:
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.closeCreateFolder
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Cancel\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  loading:
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.createFolderSave(
                                                                      _vm.createFolder
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Create\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.noDownloadFolder
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onTooltip =
                                                              ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "px-1",
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                      disabled:
                                                                        _vm.isLoading
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFolder(
                                                                          _vm.currentPath
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  onTooltip
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-folder-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Download folder")
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.noUploadFiles
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition",
                                                      "close-on-content-click": false,
                                                      "close-on-click": false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onTooltip =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-1",
                                                                                  attrs: {
                                                                                    left:
                                                                                      _vm.root !==
                                                                                        _vm.htmlRoot ||
                                                                                      _vm.noNewCreative,
                                                                                    color:
                                                                                      "success",
                                                                                    disabled:
                                                                                      _vm.isLoading
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu,
                                                                                  onTooltip
                                                                                )
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            mdi-upload\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Upload file(s)"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.uploadFilesMenu,
                                                      callback: function($$v) {
                                                        _vm.uploadFilesMenu = $$v
                                                      },
                                                      expression:
                                                        "uploadFilesMenu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          width: _vm.menuWidth
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-5 py-2"
                                                          },
                                                          [
                                                            _c("v-file-input", {
                                                              attrs: {
                                                                rules:
                                                                  _vm.uploadFilesRules,
                                                                accept:
                                                                  _vm.acceptedFileExtensions,
                                                                "show-size": 1000,
                                                                "error-messages":
                                                                  _vm.uploadFilesErrors,
                                                                multiple: "",
                                                                counter: "",
                                                                color:
                                                                  "secondary",
                                                                label:
                                                                  "Upload File(s)"
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.uploadFilesChange,
                                                                "click:clear": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.clearUploadFilesFormDataFiles()
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "selection",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var index =
                                                                        ref.index
                                                                      var text =
                                                                        ref.text
                                                                      return [
                                                                        index <
                                                                        2
                                                                          ? _c(
                                                                              "v-chip",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  dark:
                                                                                    "",
                                                                                  label:
                                                                                    "",
                                                                                  small:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      text
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : index ===
                                                                            2
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-overline grey--text text--darken-3 mx-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "+" +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .uploadFiles
                                                                                        .length -
                                                                                        2
                                                                                    ) +
                                                                                    " File(s)"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.uploadFiles,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.uploadFiles = $$v
                                                                },
                                                                expression:
                                                                  "uploadFiles"
                                                              }
                                                            }),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "prepend-icon":
                                                                  "mdi-microsoft-onedrive",
                                                                "append-icon": _vm.hasOneDriveAttachment()
                                                                  ? "mdi-close"
                                                                  : "",
                                                                label:
                                                                  "Upload File(s) from OneDrive",
                                                                readonly: "",
                                                                dense: ""
                                                              },
                                                              on: {
                                                                "click:prepend": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openOneDrive()
                                                                },
                                                                "click:append": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.clearUploadFilesFormDataOneDrive()
                                                                },
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openOneDrive()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.oneDriveFileNames,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.oneDriveFileNames = $$v
                                                                },
                                                                expression:
                                                                  "oneDriveFileNames"
                                                              }
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "font-italic pl-7 inline-block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Valid file extensions: " +
                                                                    _vm._s(
                                                                      _vm.acceptedFileExtensions
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  disabled:
                                                                    _vm.uploadingFiles ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.closeUploadFiles
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Cancel\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  loading:
                                                                    _vm.uploadingFiles ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.uploadFilesSave()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Upload\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.root === _vm.htmlRoot &&
                                            !_vm.noNewCreative
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition",
                                                      "close-on-content-click": false,
                                                      "close-on-click": false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onTooltip =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-1",
                                                                                  attrs: {
                                                                                    left:
                                                                                      "",
                                                                                    color:
                                                                                      "success",
                                                                                    disabled:
                                                                                      _vm.isLoading
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu,
                                                                                  onTooltip
                                                                                )
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            mdi-text-box-plus\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "New Creative"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.newCreativeMenu,
                                                      callback: function($$v) {
                                                        _vm.newCreativeMenu = $$v
                                                      },
                                                      expression:
                                                        "newCreativeMenu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          width: _vm.menuWidth
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-5 py-2"
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "error-messages":
                                                                  _vm.newCreativeErrors,
                                                                required: "",
                                                                label:
                                                                  "File Name",
                                                                loading:
                                                                  _vm.creatingCreative
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.newCreative.$touch()
                                                                },
                                                                blur: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$v.newCreative.$touch()
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return _vm.addCreative(
                                                                    _vm.newCreative
                                                                  )
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "append",
                                                                    fn: function() {
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.newCreativeExt
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.newCreative,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.newCreative = $$v
                                                                },
                                                                expression:
                                                                  "newCreative"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary",
                                                                  disabled:
                                                                    _vm.creatingCreative ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeNewCreative()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Cancel\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  loading:
                                                                    _vm.creatingCreative ||
                                                                    _vm.isLoading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addCreative(
                                                                      _vm.newCreative
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Create\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      !_vm.noDeleteChecked
                        ? _c(
                            "v-sheet",
                            { staticClass: "pr-3", attrs: { elevation: "4" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    right: "",
                                    "x-offset": "",
                                    origin: "right",
                                    transition: "slide-x-transition"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var onMenu = ref.on
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "px-1",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.checkedItems ||
                                                                    _vm
                                                                      .checkedItems
                                                                      .length ===
                                                                      0,
                                                                  fab: "",
                                                                  small: "",
                                                                  icon: "",
                                                                  color:
                                                                    "error",
                                                                  height: "30"
                                                                }
                                                              },
                                                              Object.assign(
                                                                {},
                                                                onMenu,
                                                                onTooltip
                                                              )
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Delete " +
                                                      _vm._s(
                                                        _vm.checkedItems.length
                                                      ) +
                                                      " checked item(s)"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3145086245
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-5" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Are you sure you want to delete the checked items?"
                                            )
                                          ])
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "tertiary",
                                                disabled: _vm.isLoading
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Cancel\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                loading: _vm.isLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteChecked()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Yes\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.noDeleteChecked
                        ? _c("v-badge", {
                            attrs: {
                              content: _vm.checkedItems.length,
                              overlap: "",
                              color:
                                _vm.checkedItems && _vm.checkedItems.length > 0
                                  ? "secondary"
                                  : "grey"
                            }
                          })
                        : _vm._e(),
                      !_vm.noDownloadChecked
                        ? _c(
                            "v-sheet",
                            {
                              staticClass: "pr-3 ml-4",
                              attrs: { elevation: "4" }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var onTooltip = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "px-1",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.filesChecked ||
                                                      _vm.filesChecked
                                                        .length === 0,
                                                    fab: "",
                                                    small: "",
                                                    icon: "",
                                                    color: "success",
                                                    height: "30"
                                                  },
                                                  on: {
                                                    click: _vm.downloadChecked
                                                  }
                                                },
                                                onTooltip
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-cloud-download")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4042916534
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Download " +
                                        _vm._s(_vm.filesChecked.length) +
                                        " checked file(s)"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.noDownloadChecked
                        ? _c("v-badge", {
                            attrs: {
                              content: _vm.filesChecked.length,
                              overlap: "",
                              color:
                                _vm.filesChecked && _vm.filesChecked.length > 0
                                  ? "secondary"
                                  : "grey"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _vm._l(12, function(index) {
                        return _c("v-skeleton-loader", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoading,
                              expression: "isLoading"
                            }
                          ],
                          key: index,
                          staticClass: "my-4 ml-7",
                          attrs: { text: "", type: "text", width: "500" }
                        })
                      }),
                      _c("v-treeview", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingCount === 0,
                            expression: "loadingCount === 0"
                          }
                        ],
                        staticClass: "pt-1",
                        staticStyle: { height: "46vh", "overflow-y": "auto" },
                        attrs: {
                          items: _vm.blobs,
                          dense: "",
                          hoverable: "",
                          selectable: _vm.checkable,
                          activatable: "",
                          active: _vm.selectedItems,
                          "selected-color": "secondary",
                          "open-on-click": ""
                        },
                        on: {
                          "update:active": _vm.itemSelected,
                          input: _vm.checkItems
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-icon", [_vm._v(_vm._s(item.displayIcon))])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.noDetailsSelected
            ? _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _vm.itemProps
                    ? _c(
                        "v-card",
                        {
                          staticClass: "my-primary",
                          staticStyle: { "overflow-y": "auto" },
                          attrs: { height: "57vh" }
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pb-2 d-flex flex-column" },
                            [
                              _c(
                                "div",
                                { staticClass: "primary--text ma-1" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary--text",
                                      attrs: { left: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.itemProps.displayIcon) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.itemProps.name) +
                                      "\n          "
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass:
                                        "ma-1 py-1 d-flex align-center",
                                      attrs: { elevation: "4" }
                                    },
                                    [
                                      _vm.root === _vm.htmlRoot &&
                                      !_vm.noNewCreative
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "px-1",
                                                              attrs: {
                                                                right: "",
                                                                color:
                                                                  "success",
                                                                disabled:
                                                                  _vm.isLoading
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.editCreative
                                                              }
                                                            },
                                                            onTooltip
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                    mdi-file-document-edit\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                558054063
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Edit Creative")
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "x-offset": "",
                                            origin: "right",
                                            transition: "slide-x-transition",
                                            "close-on-content-click": false,
                                            "close-on-click": false,
                                            width: _vm.menuWidth
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var onTooltip =
                                                                  ref.on
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "px-1",
                                                                        attrs: {
                                                                          right:
                                                                            _vm.root !==
                                                                              _vm.htmlRoot ||
                                                                            _vm.noNewCreative,
                                                                          color:
                                                                            "success",
                                                                          disabled:
                                                                            _vm.isLoading
                                                                        }
                                                                      },
                                                                      Object.assign(
                                                                        {},
                                                                        onMenu,
                                                                        onTooltip
                                                                      )
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        mdi-file-document-outline\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Rename file")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2525564341
                                          ),
                                          model: {
                                            value: _vm.renameSelectedMenu,
                                            callback: function($$v) {
                                              _vm.renameSelectedMenu = $$v
                                            },
                                            expression: "renameSelectedMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { width: _vm.menuWidth } },
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "px-5 py-2" },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.renameSelectedErrors,
                                                      required: "",
                                                      label: "File Name",
                                                      loading:
                                                        _vm.renamingSelected
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.$v.renameSelected.$touch()
                                                      },
                                                      blur: function($event) {
                                                        return _vm.$v.renameSelected.$touch()
                                                      },
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.renameSelectedSave(
                                                          _vm.renameSelected
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append",
                                                          fn: function() {
                                                            return [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.renameSelectedExt
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3028178702
                                                    ),
                                                    model: {
                                                      value: _vm.renameSelected,
                                                      callback: function($$v) {
                                                        _vm.renameSelected = $$v
                                                      },
                                                      expression:
                                                        "renameSelected"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "tertiary",
                                                        disabled:
                                                          _vm.renamingSelected ||
                                                          _vm.isLoading
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.closeRenameSelected
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Cancel\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success",
                                                        loading:
                                                          _vm.renamingSelected ||
                                                          _vm.isLoading
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.renameSelectedSave(
                                                            _vm.renameSelected
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Rename\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      !_vm.noDeleteSelected
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                right: "",
                                                "x-offset": "",
                                                origin: "right",
                                                transition:
                                                  "slide-x-transition",
                                                width: _vm.menuWidth
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onMenu = ref.on
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onTooltip =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "px-1",
                                                                            attrs: {
                                                                              color:
                                                                                "error",
                                                                              disabled:
                                                                                _vm.isLoading
                                                                            }
                                                                          },
                                                                          Object.assign(
                                                                            {},
                                                                            onMenu,
                                                                            onTooltip
                                                                          )
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        mdi-file-cancel\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Delete file"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                68127823
                                              )
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    { staticClass: "pa-5" },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Are you sure you want to delete this file?"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "tertiary",
                                                            disabled:
                                                              _vm.isLoading
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Cancel\n                    "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                            loading:
                                                              _vm.isLoading
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteSelected()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Yes\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onTooltip = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass: "px-1",
                                                          attrs: {
                                                            left: "",
                                                            color: "success",
                                                            disabled:
                                                              _vm.isLoading
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.downloadSelected
                                                          }
                                                        },
                                                        onTooltip
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-file-download\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1709732324
                                          )
                                        },
                                        [_c("span", [_vm._v("Download file")])]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list",
                                { staticClass: "my-primary" },
                                [
                                  _vm.itemProps.url
                                    ? _c(
                                        "v-list-item",
                                        { attrs: { "single-line": "" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass: "primary--text"
                                                },
                                                [
                                                  _c(
                                                    "v-dialog",
                                                    {
                                                      attrs: { persistent: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onDialog =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var onTooltip =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "v-img",
                                                                                _vm._g(
                                                                                  {
                                                                                    staticClass:
                                                                                      "grey lighten-2 pointer",
                                                                                    attrs: {
                                                                                      src:
                                                                                        "" +
                                                                                        _vm
                                                                                          .itemProps
                                                                                          .url
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "placeholder",
                                                                                          fn: function() {
                                                                                            return [
                                                                                              _c(
                                                                                                "v-skeleton-loader",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    type:
                                                                                                      "image"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ]
                                                                                          },
                                                                                          proxy: true
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    )
                                                                                  },
                                                                                  Object.assign(
                                                                                    {},
                                                                                    onDialog,
                                                                                    onTooltip
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Preview image"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3655192142
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.itemProps
                                                            .showImageSelected,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.itemProps,
                                                            "showImageSelected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "itemProps.showImageSelected"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pt-4"
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                staticClass:
                                                                  "grey lighten-2",
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .itemProps
                                                                      .url
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "tertiary",
                                                                    disabled:
                                                                      _vm.isLoading
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.closeImage()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          Close\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.itemProps.metadata &&
                                  _vm.itemProps.metadata.Width &&
                                  _vm.itemProps.metadata.Height
                                    ? _c(
                                        "v-list-item",
                                        { attrs: { "two-line": "" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("Dimensions (px)")
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass: "primary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.itemProps.metadata
                                                          .Width
                                                      ) +
                                                      "x" +
                                                      _vm._s(
                                                        _vm.itemProps.metadata
                                                          .Height
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.itemPropsProperties, function(
                                    value,
                                    name
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      { key: name, attrs: { "two-line": "" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("camelToSpace")(name)
                                                )
                                              )
                                            ]),
                                            name === "createdOn"
                                              ? _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass: "primary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(value) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.itemProps
                                                            .properties[
                                                            "createdBy"
                                                          ]
                                                            ? "by " +
                                                                _vm.itemProps
                                                                  .properties[
                                                                  "createdBy"
                                                                ]
                                                            : ""
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              : name === "lastModified"
                                              ? _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass: "primary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(value) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.itemProps
                                                            .properties[
                                                            "modifiedBy"
                                                          ]
                                                            ? "by " +
                                                                _vm.itemProps
                                                                  .properties[
                                                                  "modifiedBy"
                                                                ]
                                                            : ""
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass: "primary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(value) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticStyle: { "max-height": "550px", overflow: "hidden" },
                  attrs: { cols: "6" }
                },
                [
                  !_vm.previewHtml && _vm.loadingHtml
                    ? _c("v-skeleton-loader", { attrs: { type: "article@4" } })
                    : _vm.previewHtml
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "550px",
                            "overflow-y": "scroll"
                          }
                        },
                        [
                          _c("froala-view", {
                            model: {
                              value: _vm.previewHtml,
                              callback: function($$v) {
                                _vm.previewHtml = $$v
                              },
                              expression: "previewHtml"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.clEditorDialog,
            callback: function($$v) {
              _vm.clEditorDialog = $$v
            },
            expression: "clEditorDialog"
          }
        },
        [
          _c("ContentLibraryEditor", {
            on: {
              closeDialog: function($event) {
                return _vm.closeCLEditorDialog()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }