var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { id: "clEditor" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-slide-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "prev",
                      fn: function() {
                        return [
                          _c("v-icon", { ref: "pathPrev" }, [
                            _vm._v("\n            mdi-chevron-left\n          ")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "next",
                      fn: function() {
                        return [
                          _c("v-icon", { ref: "pathNext" }, [
                            _vm._v(
                              "\n            mdi-chevron-right\n          "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-slide-item", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var active = ref.active
                          var toggle = ref.toggle
                          return [
                            _c(
                              "span",
                              { staticClass: "pa-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      "input-value":
                                        _vm.creativePathDisplay.length === 1,
                                      "active-class": "secondary"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Root\n              "
                                    ),
                                    _vm.creativePathDisplay.length === 1
                                      ? _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-folder-open")
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.creativePathDisplay.length > 0
                                  ? _c("v-icon", { staticClass: "pl-2" }, [
                                      _vm._v("mdi-chevron-right")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._l(_vm.creativePathDisplay, function(item, i) {
                    return _c("v-slide-item", {
                      key: i,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var active = ref.active
                              var toggle = ref.toggle
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pa-1" },
                                  [
                                    i !== _vm.creativePathDisplay.length - 1
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              "input-value":
                                                i ===
                                                _vm.creativePathDisplay.length -
                                                  2,
                                              "active-class": "secondary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item) +
                                                "\n              "
                                            ),
                                            i ===
                                            _vm.creativePathDisplay.length - 2
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { right: "" } },
                                                  [_vm._v("mdi-folder-open")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              "input-value":
                                                i ===
                                                _vm.creativePathDisplay.length -
                                                  1,
                                              "active-class": "primary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item) +
                                                "\n              "
                                            ),
                                            i ===
                                            _vm.creativePathDisplay.length - 1
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { right: "" } },
                                                  [_vm._v("mdi-file-code")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                    i !== _vm.creativePathDisplay.length - 1
                                      ? _c("v-icon", { staticClass: "pl-2" }, [
                                          _vm._v("mdi-chevron-right")
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "1400",
                    scrollable: "",
                    persistent: ""
                  },
                  model: {
                    value: _vm.showTemplatesDialog,
                    callback: function($$v) {
                      _vm.showTemplatesDialog = $$v
                    },
                    expression: "showTemplatesDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "\n            Choose a Template (Optional)\n          "
                        )
                      ]),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "font-italic red--text" },
                        [
                          _vm._v(
                            "\n            By selecting a template, you will override your current creative.\n          "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("TemplateLibrary", {
                            ref: "templateLibrary",
                            on: { change: _vm.templateChange }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "tertiary",
                                disabled: _vm.savingHtml
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeTemplatesDialog()
                                }
                              }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                disabled: _vm.selectedTemplate === null,
                                loading: _vm.savingHtml
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectTemplate()
                                }
                              }
                            },
                            [_vm._v("\n              Save\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-2 d-flex justify-center",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success", disabled: _vm.savingHtml },
                          on: {
                            click: function($event) {
                              _vm.showTemplatesDialog = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n              mdi-file-code\n            "
                            )
                          ]),
                          _vm._v("\n            Choose Template\n          ")
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mx-4 d-none d-sm-flex",
                        attrs: { vertical: "" }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          icon: "",
                                          color: _vm.devicePreview
                                            ? "secondary"
                                            : "info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleEditorMenu(
                                              "devicePreview",
                                              !_vm.devicePreview
                                            )
                                          }
                                        }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "mdi-" +
                                            _vm._s(
                                              _vm.devicePreview
                                                ? "close"
                                                : "tablet-cellphone"
                                            )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.devicePreview ? "Close " : "") +
                                "Device Preview"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          icon: "",
                                          color: _vm.dragAndDrop
                                            ? "secondary"
                                            : "info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleEditorMenu(
                                              "dragAndDrop",
                                              !_vm.dragAndDrop
                                            )
                                          }
                                        }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "mdi-" +
                                            _vm._s(
                                              _vm.dragAndDrop
                                                ? "close"
                                                : "gesture-swipe"
                                            )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.dragAndDrop ? "Close " : "") +
                                "Drag & Drop Menu"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-2 pb-0",
                      attrs: {
                        cols: "12",
                        sm: "12",
                        lg:
                          (_vm.devicePreview &&
                            (!_vm.deviceWidth || _vm.deviceWidth < 600)) ||
                          _vm.dragAndDrop
                            ? "8"
                            : "12"
                      }
                    },
                    [
                      _c("froala", {
                        ref: "editor",
                        attrs: {
                          "on-manual-controller-ready": _vm.initialize,
                          config: _vm.froalaConfig
                        },
                        model: {
                          value: _vm.editorData,
                          callback: function($$v) {
                            _vm.editorData = $$v
                          },
                          expression: "editorData"
                        }
                      }),
                      _c("ImageManager", {
                        attrs: {
                          source: _vm.imageRoot,
                          destination: _vm.imageDestination
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.devicePreview,
                          expression: "devicePreview"
                        }
                      ],
                      attrs: {
                        cols: "12",
                        lg:
                          !_vm.deviceWidth || _vm.deviceWidth < 600 ? "4" : "12"
                      }
                    },
                    [
                      _c("SplitDevicePreview", {
                        ref: "splitDevicePreview",
                        attrs: { html: _vm.editorData, "no-desktop": "" },
                        on: {
                          change: function(value) {
                            return _vm.changedDevice(value, _vm.i)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dragAndDrop,
                          expression: "dragAndDrop"
                        }
                      ],
                      attrs: { cols: "12", lg: "4" }
                    },
                    [
                      _c("DragAndDrop", {
                        model: {
                          value: _vm.dragAndDropExpanded,
                          callback: function($$v) {
                            _vm.dragAndDropExpanded = $$v
                          },
                          expression: "dragAndDropExpanded"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "tertiary", disabled: _vm.savingHtml },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm.inCodeView
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        readonly: "",
                                        dark: "",
                                        color: "grey",
                                        loading: _vm.savingHtml
                                      }
                                    },
                                    on
                                  ),
                                  [_vm._v("\n            Save\n          ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1822859395
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Please exit Code View to save Creative")
                      ])
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "success", loading: _vm.savingHtml },
                      on: {
                        click: function($event) {
                          return _vm.saveCreativeClick()
                        }
                      }
                    },
                    [_vm._v("\n        Save\n      ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }