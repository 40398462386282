<template>
  <div>
    <v-card id="clEditor">
      <v-card-title>
        <v-slide-group>
          <template v-slot:prev>
            <v-icon ref="pathPrev">
              mdi-chevron-left
            </v-icon>
          </template>
          <v-slide-item v-slot:default="{ active, toggle }">
            <span class="pa-1">
              <v-btn
                small
                :input-value="creativePathDisplay.length === 1"
                active-class="secondary"
              >
                Root
                <v-icon
                  v-if="creativePathDisplay.length === 1"
                  right
                >mdi-folder-open</v-icon>
              </v-btn>
              <v-icon
                v-if="creativePathDisplay.length > 0"
                class="pl-2"
              >mdi-chevron-right</v-icon>
            </span>
          </v-slide-item>
          <v-slide-item
            v-for="(item, i) in creativePathDisplay"
            :key="i"
            v-slot:default="{ active, toggle }"
          >
            <span class="pa-1">
              <v-btn
                v-if="i !== creativePathDisplay.length - 1"
                small
                :input-value="i === creativePathDisplay.length - 2"
                active-class="secondary"
              >
                {{ item }}
                <v-icon
                  v-if="i === creativePathDisplay.length - 2"
                  right
                >mdi-folder-open</v-icon>
              </v-btn>
              <v-btn
                v-else
                small
                :input-value="i === creativePathDisplay.length - 1"
                active-class="primary"
              >
                {{ item }}
                <v-icon
                  v-if="i === creativePathDisplay.length - 1"
                  right
                >mdi-file-code</v-icon>
              </v-btn>
              <v-icon
                v-if="i !== creativePathDisplay.length - 1"
                class="pl-2"
              >mdi-chevron-right</v-icon>
            </span>
          </v-slide-item>
          <template v-slot:next>
            <v-icon ref="pathNext">
              mdi-chevron-right
            </v-icon>
          </template>
        </v-slide-group>
      </v-card-title>
      <v-card-text>
        <v-dialog
          v-model="showTemplatesDialog"
          max-width="1400"
          scrollable
          persistent
        >
          <v-card>
            <v-card-title>
              Choose a Template (Optional)
            </v-card-title>
            <v-card-subtitle
              class="font-italic red--text"
            >
              By selecting a template, you will override your current creative.
            </v-card-subtitle>
            <v-card-text>
              <TemplateLibrary
                ref="templateLibrary"
                @change="templateChange"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
                :disabled="savingHtml"
                @click="closeTemplatesDialog()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                :disabled="selectedTemplate === null"
                :loading="savingHtml"
                @click="selectTemplate()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col
            cols="12"
            class="py-2 d-flex justify-center"
          >
            <v-btn
              color="success"
              :disabled="savingHtml"
              @click="showTemplatesDialog = true"
            >
              <v-icon
                left
              >
                mdi-file-code
              </v-icon>
              Choose Template
            </v-btn>
            <v-divider
              vertical
              class="mx-4 d-none d-sm-flex"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  icon
                  :color="devicePreview ? 'secondary' : 'info'"
                  class="mx-1"
                  v-on="{ ...onTooltip }"
                  @click="toggleEditorMenu('devicePreview', !devicePreview)"
                >
                  <v-icon>mdi-{{ devicePreview ? 'close' : 'tablet-cellphone' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ devicePreview ? 'Close ' : '' }}Device Preview</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  icon
                  :color="dragAndDrop ? 'secondary' : 'info'"
                  class="mx-1"
                  v-on="{ ...onTooltip }"
                  @click="toggleEditorMenu('dragAndDrop', !dragAndDrop)"
                >
                  <v-icon>mdi-{{ dragAndDrop ? 'close' : 'gesture-swipe' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ dragAndDrop ? 'Close ' : '' }}Drag & Drop Menu</span>
            </v-tooltip>
          </v-col>
          <v-col
            class="pt-2 pb-0"
            cols="12"
            sm="12"
            :lg="(devicePreview && (!deviceWidth || deviceWidth < 600)) || dragAndDrop ? '8' : '12'"
          >
            <froala
              ref="editor"
              v-model="editorData"
              :on-manual-controller-ready="initialize"
              :config="froalaConfig"
            />
            <ImageManager
              :source="imageRoot"
              :destination="imageDestination"
            />
          </v-col>
          <v-col
            v-show="devicePreview"
            cols="12"
            :lg="!deviceWidth || deviceWidth < 600 ? '4' : '12'"
          >
            <SplitDevicePreview
              ref="splitDevicePreview"
              :html="editorData"
              no-desktop
              @change="value => changedDevice(value, i)"
            />
          </v-col>
          <v-col
            v-show="dragAndDrop"
            cols="12"
            lg="4"
          >
            <DragAndDrop v-model="dragAndDropExpanded" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="tertiary"
          :disabled="savingHtml"
          @click="closeDialog()"
        >
          Cancel
        </v-btn>
        <v-tooltip
          v-if="inCodeView"
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              readonly
              dark
              color="grey"
              :loading="savingHtml"
              v-on="on"
            >
              Save
            </v-btn>
          </template>
          <span>Please exit Code View to save Creative</span>
        </v-tooltip>
        <v-btn
          v-else
          color="success"
          :loading="savingHtml"
          @click="saveCreativeClick()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import '@/plugins/vue-froala-wysiwyg'
import FroalaEditor from 'froala-editor/js/froala_editor.min'
import store from '@/store'
import {
  fileExtensions,
  creative,
  CreativeModel
} from '@/shared/models/content-library'
import { dragAndDropMixin } from '@/shared/mixins/mailing'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { wscMixin } from '@/shared/mixins/general'
import { azBlobService } from '@/shared/services'
import 'codemirror/lib/codemirror.css'
import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/xml/xml'

import ImageManager from '@/views/pages/components/content-library/ImageManager'

export default {
  name: 'ContentLibraryEditor',

  components: {
    TemplateLibrary: () => import('@/views/pages/components/content-library/TemplateLibrary'),
    SplitDevicePreview: () => import('@/views/pages/components/mailing/SplitDevicePreview'),
    DragAndDrop: () => import('@/views/pages/components/mailing/DragAndDrop'),
    ImageManager
  },

  mixins: [dragAndDropMixin, wscMixin],

  data () {
    return {
      savingHtml: false,
      imageRoot: process.env.VUE_APP_CL_IMAGES,
      initControls: null,
      showTemplatesDialog: false,
      selectedTemplate: null,
      inCodeView: false,
      devicePreview: false,
      deviceWidth: null,
      dragAndDrop: false,
      dragAndDropExpanded: [0, 1, 2]
    }
  },

  computed: {
    ...mapGetters(['isLoading', 'simulatedCustomerCrmId']),
    ...mapGetters('creative', ['getCreative']),
    editorData: {
      get: function () {
        return this.getCreative.Html || ''
      },
      set: function (val) {
        this.setHtml(val)
      }
    },
    creativePathDisplay () {
      return this.getCreative.Path ? this.getCreative.Path.replace(process.env.VUE_APP_CL_HTML, '').split('/') : []
    },
    imageDestination () {
      return this.getCreative.ImagePath
    },
    froalaConfig () {
      const context = this
      return {
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting'
            ]
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote'
            ]
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertTable',
              'emoticons',
              'specialCharacters',
              'insertHR'
            ]
          },
          moreMisc: {
            'buttons': ['undo', 'redo', 'html', 'print', 'spellChecker', 'selectAll', 'help', 'fullscreen'],
            'align': 'right'
          }
        },
        pluginsEnabled: [
          'align',
          'charCounter',
          'codeBeautifier',
          'codeView',
          'colors',
          'draggable',
          'fontFamily',
          'fontSize',
          'fullscreen',
          'image',
          'lineBreaker',
          'emoticons',
          'entities',
          'inlineStyle',
          'inlineClass',
          'lineHeight',
          'lists',
          'link',
          'paragraphFormat',
          'paragraphStyle',
          'quickInsert',
          'specialCharacters',
          'quote',
          'table',
          'wordPaste',
          'print'
        ],
        htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote',
          'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog',
          'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
          'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main',
          'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param',
          'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span',
          'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track',
          'u', 'ul', 'var', 'video', 'wbr'],
        fontSize: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '20', '22', '24', '26', '28', '30', '36', '48', '60', '72'],
        // tableEditButtons: [['tableHeader', 'tableRemove'], ['tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle']],
        imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageUploadURL: `${process.env.VUE_APP_INSCRIPTIO_API}${process.env.VUE_APP_API}/azureblob/editor/saveImage`,
        imageUploadMethod: process.env.VUE_APP_FROALA_IMAGE_METHOD,
        imageMaxSize: process.env.VUE_APP_FROALA_IMAGE_MAX_SIZE,
        imageAllowedTypes: process.env.VUE_APP_FROALA_IMAGE_TYPES.split(','),
        imageUploadParams: {
          tenantCrmId: context.simulatedCustomerCrmId(),
          root: context.imageDestination
        },
        requestHeaders: {
          authorization: store.getters['auth/getBearerToken']()
        },
        linkList: [
          {
            text: 'MMS',
            href: 'https://www.mmslists.com',
            target: '_blank'
          },
          {
            text: 'Med-Pub',
            href: 'https://www.med-pub.com',
            target: '_blank'
          },
          {
            text: 'Facebook',
            href: 'https://facebook.com/mmsemail',
            target: '_blank'
          }
        ],
        events: {
          'initialized': function () {
            try {
              WEBSPELLCHECKER.init({
                container: this.el
              })
            } catch (exception) {
              console.log(exception)
            }
          },
          'image.beforeUpload': function (images) {
            context.$store.dispatch('auth/refreshTokens')
          },
          'commands.after': function (cmd, param1, param2) {
            if (cmd === 'html') {
              context.inCodeView = !context.inCodeView
            }
          },
          'drop': function (event) {
            // since refs can be created in different order, need to compare against class list
            let editor = context.$refs.editor.getEditor()

            // Focus at the current posisiton.
            editor.markers.insertAtPoint(event.originalEvent)
            let $marker = editor.$el.find('.fr-marker')
            $marker.replaceWith(FroalaEditor.MARKERS)
            editor.selection.restore()

            // Save into undo stack the current position.
            if (!editor.undo.canDo()) editor.undo.saveStep()

            // Insert HTML.
            let dragItem = context.dragAndDropMixin_items.find(x => x.id === localStorage.getItem('drag'))
            editor.html.insert(dragItem.content)
            editor.events.trigger('focus')
            editor.events.trigger('blur')

            // insert style
            editor.html.insert(context.dragAndDropMixin_style.style)

            // Save into undo stack the changes.
            editor.undo.saveStep()

            // Stop event propagation.
            event.preventDefault()
            event.stopPropagation()

            // Firefox show cursor.
            if (editor.core.hasFocus() && editor.browser.mozilla) {
              editor.events.disableBlur()
              setTimeout(function () {
                editor.$el.blur().focus()
                editor.events.enableBlur()
              }, 0)
            }
          }
        },
        htmlRemoveTags: ['script'],
        htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i'],
        htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async',
          'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite',
          'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer',
          'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden',
          'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max',
          'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder',
          'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped',
          'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style',
          'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'xt', 'xtsn', 'qstparam', 'description'],
        fullPage: true,
        spellcheck: true,
        pastePlain: true,
        attribution: false,
        // zIndex: 9990,
        scrollableContainer: '#clEditor',
        codeMirror: CodeMirror,
        codeMirrorOptions: {
          indentWithTabs: true,
          lineNumbers: true,
          lineWrapping: true,
          mode: 'text/html',
          tabMode: 'indent',
          tabSize: 2
        },
        placeholderText: 'Edit Your Content Here!',
        key: process.env.VUE_APP_FROALA_LICENSE_KEY
      }
    }
  },

  created () {
    // window.addEventListener('keydown', this.keyDown)
  },

  destroyed () {
    // window.removeEventListener('keydown', this.keyDown)
  },

  mounted () {
    setTimeout(() => {
      this.createEditor()
    }, 1)
  },

  methods: {
    ...mapActions('creative', ['getSelectedCreative', 'saveCreative']),
    ...mapMutations('creative', ['setHtml', 'clearCreative']),
    closeDialog () {
      this.savingHtml = false
      this.$emit('closeDialog')
    },
    async saveCreativeClick () {
      this.savingHtml = true
      // if (this.inCodeView) {
      //   let editor = this.$refs.editor.getEditor()
      //   // console.log('saving while in code view')
      //   this.editorData = editor.codeView.get()
      // }
      let creative = new CreativeModel()
      creative.TenantCrmId = this.getCreative.TenantCrmId
      creative.Html = this.editorData
      creative.FileName = this.getCreative.FileName
      creative.ImagePath = this.getCreative.ImagePath
      creative.Path = this.getCreative.Path + '/'
      creative.Modified = new Date()
      creative.SaveToContentLibrary = false

      await this.saveCreative({ model: creative })
      this.closeDialog()
    },
    createEditor () {
      this.initControls.initialize()
    },
    initialize (initControls) {
      this.initControls = initControls
      this.deleteAll = () => {
        this.initControls.getEditor().html.set('')
        this.initControls.getEditor().undo.reset()
        this.initControls.getEditor().undo.saveStep()
      }
    },
    closeTemplatesDialog () {
      this.showTemplatesDialog = false
      this.selectedTemplate = null
      this.$refs.templateLibrary.clearSelection()
    },
    templateChange (template) {
      this.selectedTemplate = template
    },
    async selectTemplate () {
      this.savingHtml = true
      await azBlobService.copyTemplatesHTMLToCL({
        tenantCrmId: this.simulatedCustomerCrmId(),
        objectToCopy: this.selectedTemplate.id,
        destinationPath: this.getCreative.Path + '/'
      })

      let creative = new CreativeModel()
      let fullPath = this.getCreative.Path + '/' + this.getCreative.FileName
      creative.Path = fullPath
      creative.TenantCrmId = this.simulatedCustomerCrmId()

      await this.getSelectedCreative({ model: creative })
      this.savingHtml = false
      this.closeTemplatesDialog()
    },
    changedDevice (device, i) {
      this.deviceWidth = device.width
    },
    toggleEditorMenu (menuName, isOn) {
      let options = ['devicePreview', 'dragAndDrop']
      if (isOn) {
        options.forEach(item => {
          this[item] = menuName === item
        })
      } else {
        this[menuName] = isOn
      }
    }
    // keyDown (event) {
    //   // console.log(event)
    //   if ((event.target).parentNode.parentNode.parentNode.className === 'fr-wrapper') {
    //     let editor = this.$refs.editor.getEditor()
    //     // console.log('key pressed while in code view')
    //     // this.editorData = editor.codeView.get()
    //     // console.log(editor.codeView.get())
    //   }
    // }
  }
}
</script>

<style>
</style>
